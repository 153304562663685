import React from "react";

import Layout from "../components/Layout";
import { MyLocationEnum } from "../components/Layout/utils/enum";
import VideoRoom from "../components/ConferenceRoom/components/VideoRoom";
import SEO, { MetaOG } from "../components/Seo";
import { useStaticQuery, graphql } from "gatsby";

interface locationConditionSettings {
  showHome: boolean;
  showGoLive: boolean;
  showClose: boolean;
  backTo?: string;
}

interface Props {
  location: Location;
}
export default ({ location }: Props) => {
  const seo_og: MetaOG = {
    title: "Bein útsending",
    description: "Bein útsending DEMO",
  };

  const data = useStaticQuery(
    graphql`
      query {
        Background: file(relativePath: { eq: "erwan-hesry-WPTHZkA-M4I-unsplash.jpg" }) {
          childImageSharp {
            fluid(quality: 50, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <Layout location={location} myLocation={MyLocationEnum.Live}>
      <SEO
        title="Bein útsending"
      />
      <VideoRoom
        videoLink="https://player.vimeo.com/video/476244521" //https://player.vimeo.com/video/456473434
        slidoLink="https://app.sli.do/event/8xkhlyrq"
        backgroundImage={data.Background.childImageSharp.fluid}
      />
    </Layout>
  );
};
