import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { animation_blur } from "../utils/animations";
import { mediaMax } from "../../../utils/breakpoints";
import NordurljosSVG from "../../Svg/NordurljosSVG";
import MobileSlido from "./Slido/MobileSlido";

interface VideoRoomProps {
  videoLink: string;
  slidoLink: string;
  backgroundImage?: any;
  lineSvgName?: string;
}

const VideoRoom = ({
  videoLink,
  slidoLink,
  backgroundImage,
  lineSvgName,
}: VideoRoomProps) => {
  const data = useStaticQuery(
    graphql`
      query {
        qrCode: file(relativePath: { eq: "SlidoQR.png" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const [Active, setActive] = useState("notActive");
  const [Blurred, setBlurred] = useState(false);
  const [Lines, setLines] = useState(null);
  const [LiveBackground, setLiveBackground] = useState(
    <Img fluid={backgroundImage} style={{ height: "100%" }} />
  );

  useEffect(() => {
    if (lineSvgName != null && lineSvgName != undefined) {
      if (lineSvgName == "NordurljosSVG") {
        setLines(NordurljosSVG);
      }
    }
  }, []);

  // ===============  USE THIS IF YOU WANT A DYNAMIC BACKGROUND BASED ON VALUE FROM LIST OBJECT -- NEED TO ADD IMPORTS OF IMAGES TO GRAPHQL ABOVE =====================
  // useEffect(() => {
  //   const dataArray = data.allSpeaker.edges.map(item => {
  //     return item.node
  //   })

  //   const IntervalFunction = () => {
  //     const ActiveEvent = getLiveEvent(dataArray)[0]
  //     if (ActiveEvent != null) {
  //       switch (ActiveEvent.room) {
  //         case "Nordurljos":
  //           setLiveBackground(
  //             <Img
  //               fluid={data.Nordurljos.childImageSharp.fluid}
  //               style={{ height: "100%" }}
  //             />
  //           )
  //           setLines(NordurljosSVG);
  //           break
  //         case "Kaldalon":
  //           setLiveBackground(
  //             <Img
  //               fluid={data.Kaldalon.childImageSharp.fluid}
  //               style={{ height: "100%" }}
  //             />
  //           )
  //           setLines(NordurljosSVG);
  //           break
  //         default:
  //           setLiveBackground(
  //             <Img
  //               fluid={data.Nordurljos.childImageSharp.fluid}
  //               style={{ height: "100%" }}
  //             />
  //           )
  //           setLines(NordurljosSVG);
  //           break
  //       }
  //     } else {
  //       setLiveBackground(
  //         <Img
  //           fluid={data.Nordurljos.childImageSharp.fluid}
  //           style={{ height: "100%" }}
  //         />
  //       )
  //       setLines(NordurljosSVG);
  //     }
  //   }

  //   const intervalId = setInterval(async () => {
  //     IntervalFunction()
  //   }, 1000 * 60)

  //   IntervalFunction()

  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [])

  return (
    <StyledContainer>
      <SvgContainer>{Lines}</SvgContainer>
      <StyledBackgroundImage className={Blurred == true ? "blurred" : ""}>
        {LiveBackground}
      </StyledBackgroundImage>
      <StyledVideoContainer className={Active}>
        <iframe
          src={videoLink}
          className="video"
          width="50%"
          height="50%"
          frameBorder="0"
          allowFullScreen
          allow="autoplay; fullscreen"
        ></iframe>
        <SlidoFrameContainer>
          <iframe
            src={slidoLink}
            className="slidoFrame"
            height="100%"
            width="100%"
            frameBorder="0"
          ></iframe>
        </SlidoFrameContainer>
        <div className="mobileSlido__container">
          <MobileSlido slidoLink={slidoLink} />
        </div>
      </StyledVideoContainer>
      {/* <QrCodeContainer>
        <h2>Taktu þátt í umræðunni!</h2>
        <Img fixed={data.qrCode.childImageSharp.fixed} />
      </QrCodeContainer> */}
    </StyledContainer>
  );
};

const SvgContainer = styled.div`
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
  }
`;

const SlidoFrameContainer = styled.div`
  margin-left: 30px;
  height: 50%;
  max-width: 400px;
  min-width: 380px;

  h2 {
    font-weight: 300;
    color: #fff;
    text-align: center;
  }
  .slidoFrame {
    top: 0;
  }

  @media ${mediaMax.tabletL} {
    display: none;
  }
`;

const StyledBackgroundImage = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  transition: opacity 0s !important;

  img {
    transition: opacity 0s !important;
    opacity: 1 !important;
  }
`;

const StyledVideoContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  z-index: 21;

  &.active {
    transform: translate3d(0, -100vh, 0);
    transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.notActive {
    transform: translate3d(0, 0, 0);
  }

  .mobileSlido__container {
    display: none;
  }
  @media ${mediaMax.tabletL} {
    > iframe {
      height: 85% !important;
      width: 85% !important;
    }
  }

  @media ${mediaMax.tabletL} {
    flex-direction: column;
    .video {
      width: 90% !important;
      height: 30vh !important;
    }

    .mobileSlido__container {
      display: block;
      max-width: 450px;
      width: 90%;
    }
  }
`;

const StyledContainer = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

const QrCodeContainer = styled.div`
  position: relative;
  width: 200px;
  height: 265px;
  top: -285px;
  right: -11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 21;

  > h2 {
    font-family: "Adelle";
    color: #000;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    font-size: 36px;
  }
  > img {
    height: 50px;
    width: 50px;
  }

  @media ${mediaMax.tabletL} {
    display: none;
  }

  @media (max-height: 800px) {
    display: none;
  }
`;

export default VideoRoom;
